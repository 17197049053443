import {EffectCallback, useEffect, useRef, useState} from 'react';
import {getTidFromUrl, readT3Table} from "./utils";
import {clearTournament, openModal, setMaxRounds, setPlayers} from "./appSlice";
import {useAppDispatch} from "./app/hooks";
import useModalEventBus from "./features/modal/ModalBus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {getRoundMax} from "./features/tournament/tournament-utils";

export default function useWindowDimensions(): { width: number, height: number } {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : 0;
        const height = hasWindow ? window.innerHeight : 0;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
}

export function useOnMountUnsafe(effect: EffectCallback) {
    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            effect()
        }
    }, [])
}

export function useT3Check() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate(); // <-- get history from hook

    const newTournamentWithPlayers = (players: []) => {
        dispatch(clearTournament())
        dispatch(setPlayers(players));
        dispatch(setMaxRounds(getRoundMax(players)))
        navigate("/")
    }

    useModalEventBus(useT3Check, newTournamentWithPlayers);

    useOnMountUnsafe(function () {
        let tid = getTidFromUrl();
        if (tid !== null) {
            readT3Table(tid as string).then(function (players) {
                if (!players || players.length === 0) return;
                dispatch(openModal({
                    modalText: t("areYouSureNewTournamentWithPlayerList", {
                        playersCount: players.length,
                        providerName: "Tabletop"
                    }),
                    buttonActions: [{
                        label: t("yes"),
                        closeOnClick: true,
                        args: players,
                        targetComponent: useT3Check.name,
                        method: newTournamentWithPlayers.name,
                    }, {
                        label: t("no"),
                        closeOnClick: true
                    }],
                }))
            });
        }
    });
}