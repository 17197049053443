import React from "react";
import xenourl from "../../assets/sponsors/xenosimple.png"
import LanguageSelector from "../../LanguageSelector";
import {useTranslation} from "react-i18next";

function Footer() {
    const [t] = useTranslation();

    return (
        <>
            <footer>
                <h3>{t('partners')}</h3>
                <div className={"partners"}>

                    <a className={"partner"} href={"https://www.xenomorphe.ch"} target={"_blank"}>
                        <img src={xenourl}/>
                        <span>Magasin de jeux à Genève</span>
                    </a>

                </div>
                <div className="copyright">
                    <LanguageSelector/>
                    © larondesuisse.com <br/>
                    Contact (bugs, questions, suggestions,...) : <a
                    href={"mailto:contact@larondesuisse.com"}>contact@larondesuisse.com</a>
                </div>
            </footer>

        </>
    );

}

export default Footer;
