import {AnyAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './app/store';
import {ModalData} from "./features/modal/Modal";
import {getUniqueId} from "./utils";

export interface Round {
    done: boolean;
    id: number
    tables: TableData[]
}

export enum CounterState {
    STARTED = "started",
    PAUSED = "paused",
    STOPPED = "stopped"
}

export enum ImpressionMode {
    Pairing = "pairing",
    Ranking = "ranking"
}

export enum TableState {
    WAITING = 'waiting',
    DONE = 'done'
}

export enum TournamentStatus {
    PREPARING,
    EDITING,
    STARTED,
    DONE
}

export interface TableData {
    players: PlayerTableData[]
}

export interface PlayerTableData {
    score: number;
    id: string
    win: number
    draw: number
    loss: number
}

export interface PlayerStartData {
    id: string
    name: string,
    group: string,
    externalRank: number
}

export interface PlayerData extends PlayerStartData {
    score: number;
    gd: number;
    points: number,
    resistance: number,
    win: number,
    loss: number,
    draw: number,
    dropped: boolean,
    opponents: string[]
}

export interface AppState {
    playersOfSameGroupCantMeet: boolean;
    useExternalRanking: boolean;
    tournamentState: TournamentStatus
    winPoints: number
    lossPoints: number
    drawPoints: number
    roundDuration: number
    rounds: Round[]
    players: PlayerData[]
    modals: ModalData[]
    tournamentName: string
    maxRounds: number
    impressionMode: ImpressionMode
    countDownEndAt: number
    useGoalAverage: boolean
    useGroup: boolean
    game: string;
    counterState: CounterState
}

const initialState: AppState = {
    tournamentName: "",
    tournamentState: TournamentStatus.PREPARING,
    useExternalRanking: false,
    winPoints: 3,
    lossPoints: 0,
    drawPoints: 1,
    roundDuration: 30,
    players: [],
    rounds: [],
    modals: [],
    maxRounds: 0,
    impressionMode: ImpressionMode.Pairing,
    countDownEndAt: 0,
    useGoalAverage: false,
    playersOfSameGroupCantMeet: false,
    useGroup: false,
    game: "",
    counterState: CounterState.PAUSED

};


export const appSlice = createSlice({
                                        name: 'app',
                                        initialState,
                                        // The `reducers` field lets us define reducers and generate associated actions
                                        reducers: {
                                            setWinPoints: (state: AppState, action: PayloadAction<number>) => {
                                                state.winPoints = action.payload;
                                            },

                                            setGame: (state: AppState, action: PayloadAction<string>) => {
                                                state.game = action.payload;
                                            },

                                            setLossPoints: (state: AppState, action: PayloadAction<number>) => {
                                                state.lossPoints = action.payload;
                                            },

                                            setDrawPoints: (state: AppState, action: PayloadAction<number>) => {
                                                state.drawPoints = action.payload;
                                            },

                                            setCountdownEnd: (state: AppState, action: PayloadAction<number>) => {
                                                state.countDownEndAt = action.payload;
                                            },


                                            setUseExternalRanking: (state: AppState, action: PayloadAction<boolean>) => {
                                                state.useExternalRanking = action.payload;
                                            },

                                            setRoundDuration: (state: AppState, action: PayloadAction<number>) => {
                                                state.roundDuration = action.payload;
                                            },

                                            setRound: (state: AppState, action: PayloadAction<Round>) => {
                                                let round = action.payload;
                                                state.rounds[round.id] = round;
                                            },

                                            setRounds: (state: AppState, action: PayloadAction<Round[]>) => {
                                                let rounds = action.payload;
                                                state.rounds = rounds;
                                            },

                                            setTournamentStatus: (state: AppState, action: PayloadAction<TournamentStatus>) => {
                                                state.tournamentState = action.payload;
                                            },
                                            addPlayer: (state: AppState, action: PayloadAction<PlayerData>) => {
                                                state.players.push(action.payload)
                                            },
                                            setPlayer: (state: AppState, action: PayloadAction<PlayerData>) => {
                                                let existingPlayer = state.players.find(p => p.id === action.payload.id);
                                                if (existingPlayer) {
                                                    state.players[state.players.indexOf(existingPlayer)] = action.payload
                                                }
                                            },
                                            setPlayers: (state: AppState, action: PayloadAction<PlayerStartData[]>) => {
                                                state.players = action.payload.map(e => {
                                                    return {
                                                        gd: 0,
                                                        score: 0,
                                                        points: 0,
                                                        resistance: 0,
                                                        win: 0,
                                                        loss: 0,
                                                        draw: 0,
                                                        dropped: false,
                                                        victoryPoints: 0,
                                                        opponents: []
                                                        , ...e
                                                    }
                                                });
                                            },
                                            setModals: (state: AppState, action: PayloadAction<ModalData[]>) => {
                                                state.modals = action.payload;
                                            },
                                            openModal: (state: AppState, action: PayloadAction<ModalData>) => {
                                                action.payload.id = getUniqueId();
                                                state.modals.push(action.payload);
                                                return state
                                            },
                                            closeModal: (state: AppState, action: PayloadAction<string>) => {
                                                state.modals = state.modals.filter(m => m.id !== action.payload);
                                            },
                                            setTournamentName: (state, action: PayloadAction<string>) => {
                                                state.tournamentName = action.payload;
                                            },

                                            setCounterState: (state, action: PayloadAction<CounterState>) => {
                                                state.counterState = action.payload;
                                            },
                                            setMaxRounds: (state, action: PayloadAction<number>) => {
                                                state.maxRounds = action.payload;
                                            },
                                            setImpressionMode: (state, action: PayloadAction<ImpressionMode>) => {
                                                state.impressionMode = action.payload;
                                            },
                                            clearTournament: (state, action: PayloadAction) => {
                                                state.tournamentName = "";
                                                state.tournamentState = TournamentStatus.PREPARING;
                                                state.players = [];
                                                state.rounds = [];
                                            },
                                            setUseGoalAverage: (state: AppState, action: PayloadAction<boolean>) => {
                                                state.useGoalAverage = action.payload;
                                            },

                                            setUseGroup: (state: AppState, action: PayloadAction<boolean>) => {
                                                state.useGroup = action.payload;
                                            },

                                            setPlayerOfSameGroupCantMeet: (state: AppState, action: PayloadAction<boolean>) => {
                                                state.playersOfSameGroupCantMeet = action.payload;
                                            }
                                        }
                                    });

interface MatchActions {
    setGame: (game: string) => AnyAction;
    clearTournament: () => AnyAction
    addPlayer: (p: PlayerData) => AnyAction
    setPlayer: (p: PlayerData) => AnyAction
    setPlayers: (players: PlayerStartData[]) => AnyAction
    setModals: (modals: ModalData[]) => AnyAction
    closeModal: (id: string) => AnyAction
    openModal: (modal: ModalData) => AnyAction
    setWinPoints: (winPoints: number) => AnyAction;
    setLossPoints: (lossPoints: number) => AnyAction;
    setDrawPoints: (drawPoints: number) => AnyAction;
    setRoundDuration: (roundDuration: number) => AnyAction;
    setTournamentStatus: (tournamentStatus: TournamentStatus) => AnyAction;
    setUseExternalRanking: (useExternalRanking: boolean) => AnyAction;
    setTournamentName: (tournamentName: string) => AnyAction;
    setRound: (round: Round) => AnyAction;
    setRounds: (rounds: Round[]) => AnyAction;
    setMaxRounds: (rounds: number) => AnyAction;
    setCountdownEnd: (at: number) => AnyAction;
    setImpressionMode: (mode: ImpressionMode) => AnyAction
    setUseGoalAverage: (useGoalAverage: boolean) => AnyAction;
    setUseGroup: (useGroup: boolean) => AnyAction;
    setPlayerOfSameGroupCantMeet: (cantFight: boolean) => AnyAction;
    setCounterState: (counterState: CounterState) => AnyAction;

}


export const {
    addPlayer,
    setPlayer,
    setPlayers,
    setGame,
    closeModal,
    openModal,
    setModals,
    setWinPoints,
    setLossPoints,
    setDrawPoints,
    clearTournament,
    setTournamentStatus,
    setRoundDuration,
    setImpressionMode,
    setUseExternalRanking,
    setTournamentName,
    setRound,
    setRounds,
    setMaxRounds,
    setCountdownEnd,
    setUseGoalAverage,
    setUseGroup,
    setPlayerOfSameGroupCantMeet,
    setCounterState
} = appSlice.actions as MatchActions;

export const selectPlayers = (state: RootState) => state.app.players;
export const selectRounds = (state: RootState) => state.app.rounds;
export const selectTournamentState = (state: RootState) => state.app.tournamentState;
export const selectWinPoints = (state: RootState) => state.app.winPoints;
export const selectLossPoints = (state: RootState) => state.app.lossPoints;
export const selectRoundDuration = (state: RootState) => state.app.roundDuration;
export const selectUseExternalRanking = (state: RootState) => state.app.useExternalRanking;
export const selectDrawPoints = (state: RootState) => state.app.drawPoints;
export const selectModals = (state: RootState) => state.app.modals;
export const selectTournamentName = (state: RootState) => state.app.tournamentName;
export const selectRoundCount = (state: RootState) => state.app.rounds.length;
export const selectImpressionMode = (state: RootState) => state.app.impressionMode;
export const selectMaxRounds = (state: RootState) => state.app.maxRounds;
export const selectCountdownEnd = (state: RootState) => state.app.countDownEndAt;
export const selectUseGoalAverage = (state: RootState) => state.app.useGoalAverage;

export const selectPlayersOfSameGroupCantMeet = (state: RootState) => state.app.playersOfSameGroupCantMeet;

export const selectUseGroup = (state: RootState) => state.app.useGroup;
export const selectGame = (state: RootState) => state.app.game;
export const selectCounterState = (state: RootState) => state.app.counterState;

export const selectPlayerById = (id: string) => (state: RootState) =>
    state.app.players.find((player) => player.id === id) as PlayerData;

export default appSlice.reducer;
