import React from "react";
import LanguageSelector from "../../LanguageSelector";
import Version from "../ui/Version";
import Footer from "./Footer";
import Logo from "../ui/Logo";

function Changelog() {

    return (
        <>
            <div className="container">
                <header>
                    <LanguageSelector/>
                    <Logo/>
                    <Version/>
                    <h2>Changelog</h2>
                </header>
                <div className="content">

                    <h3>Version 1.0.3 (2024-05-04) </h3>
                    <ul>
                        <li>Allow to choose whether players in the same group can fight against each other</li>
                        <li>Show group names next to player in print display</li>
                    </ul>

                    <h3>Version 1.0.2 (2023-10-11)</h3>
                    <ul>
                        <li>Tabletop tournament can be imported using /tid/id in url</li>
                    </ul>

                    <h3>Version 1.0.1 (2023-10-11)</h3>
                    <ul>
                        <li>Fixed a bug where it didn't pair players properly in some situations</li>
                    </ul>

                    <h3>Version 1.0.0 (26-06-2023)</h3>
                    <ul>
                        <li>Initial release</li>
                    </ul>
                </div>
            </div>

            <Footer/>
        </>
    );

}

export default Changelog;
