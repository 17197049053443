import {
    PlayerData,
    PlayerStartData,
    PlayerTableData,
    Round,
    selectDrawPoints,
    selectGame,
    selectLossPoints,
    selectPlayerById,
    selectPlayersOfSameGroupCantMeet,
    selectUseGoalAverage,
    selectUseGroup,
    selectWinPoints,
    TableData
} from "../../appSlice";
import {currentState, deepCopy, remove, shuffle} from "../../utils";
import {store} from "../../app/store";

export function getPlayerTable(round: Round, playerId: string): TableData | undefined {
    if (!round) {
        return undefined;
    }
    let tables = round.tables;
    for (let i = 0; i < tables.length; i++) {
        for (let p = 0; p < tables[i].players.length; p++) {
            if (tables[i].players[p].id === playerId) {
                return tables[i];
            }
        }
    }
}

export function updatePlayerPoints(player: PlayerData, rounds: Round[]) {
    let points = 0;
    let loss = 0;
    let win = 0;
    let draw = 0;
    let score = 0;
    for (let round of rounds) {
        for (let table of round.tables) {
            for (let p of table.players) {
                if (p.id === player.id) {
                    score += p.score;
                    if (p.win) {
                        win += p.win;
                        points += selectWinPoints(currentState()) * p.win;
                    } else if (p.draw) {
                        draw += p.draw;
                        points += selectDrawPoints(currentState()) * p.draw;
                    } else if (p.loss) {
                        loss += p.loss;
                        points += selectLossPoints(currentState()) * p.loss;
                    }
                }
            }
        }
    }
    player.draw = draw;
    player.loss = loss;
    player.win = win;
    player.points = points;
    player.score = score;

    return player;
}

export function findTableWithPlayer(playerId: string, round: Round) {
    for (let table of round.tables) {
        for (let p of table.players) {
            if (p.id === playerId) {
                return table
            }
        }
    }
}

export function sagaDiff(score1: number, score2: number, win: boolean) {
    let diff = Math.abs(score1 - score2);

    const ecartTable = [
        [0, 3, 6, 10, 15, 20, 25, 30, 35, 36],
        [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    ];

    for (let i = 0; i < ecartTable[0].length; i++) {
        if (diff <= ecartTable[0][i]) {
            return win ? ecartTable[1][i] : ecartTable[2][i]
        }
    }

    return win ? 19 : 1;
}

export function updatePlayerResistanceAndOpponents(player: PlayerData, rounds: Round[]) {
    let resistance = 0;
    let opponents = [];
    let gd = 0;

    for (let round of rounds) {
        let tableWithPlayer = findTableWithPlayer(player.id, round);

        if (tableWithPlayer) {
            const playerTableData = tableWithPlayer.players.find(p => p.id === player.id) as PlayerTableData;

            for (let opponent of tableWithPlayer.players) {
                if (opponent.id !== player.id) {

                    switch (selectGame(currentState())) {
                        case 'saga':
                            gd += sagaDiff(playerTableData.score, opponent.score, playerTableData.win > 0);
                            break;
                        default:
                            gd += playerTableData.score - opponent.score;

                    }
                    resistance += selectPlayerById(opponent.id)(currentState()).points;
                    opponents.push(opponent.id);
                }
            }
        }

    }

    player.resistance = resistance
    player.opponents = opponents;
    player.gd = gd;
    return player

}

export function getTablePlayerIndex(table: TableData, playerId: string) {
    for (let index = 0; index < table.players.length; index++) {
        if (table.players[index].id === playerId) {
            return index;
        }
    }
}

export function getOtherPlayers(playerId: string, players: PlayerData[]) {
    return players.filter(p => p.id !== playerId)
}

export function getPlayersOrderedByPoints(players: PlayerData[]) {
    return [...players].sort((a, b) => {
        return (b.points !== a.points) ? b.points - a.points : (selectUseGoalAverage(store.getState()) && (b.gd !== a.gd) ? b.gd - a.gd : b.resistance - a.resistance); // Sort by points in descending order
    });

}

export function getPlayersOrderedByName(players: PlayerData[]) {
    return [...players].sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
}

export function getPlayersOrderedByExternalRank(players: PlayerData[]) {
    return [...players].sort((a, b) => {
        return b.externalRank - a.externalRank; // Sort by rank in descending order
    });
}

export function getStrongestPlayer(players: PlayerData[]) {
    return getPlayersOrderedByPoints(players)[0]
}

export function buildATableWithExternalRank(players: PlayerData[]): TableData {
    shuffle(players);

    let playersOrderedByRank = getPlayersOrderedByExternalRank(players).filter(p => !p.dropped);

    let strongestPlayer = playersOrderedByRank[0];
    remove(players, strongestPlayer);
    remove(playersOrderedByRank, strongestPlayer);


    let playersOfSameGroupCantMeet = selectUseGroup(store.getState()) && selectPlayersOfSameGroupCantMeet(store.getState());

    if (playersOfSameGroupCantMeet) {
        playersOrderedByRank = playersOrderedByRank.filter(p => p.group !== strongestPlayer.group);
    }

    let halfOfWeakestPlayers = playersOrderedByRank.slice(-Math.ceil(playersOrderedByRank.length / 2));

    let potentialOpponent = shuffle(halfOfWeakestPlayers)[0];

    if (potentialOpponent) {
        remove(players, potentialOpponent);
    }

    let table: TableData;
    table = {
        players: [{id: strongestPlayer.id, win: 0, score: 0, draw: 0, loss: 0}],
    };
    if (potentialOpponent !== null) {
        table.players.push({id: potentialOpponent.id, win: 0, score: 0, draw: 0, loss: 0});
    }

    return table;
}

export function buildATable(players: PlayerData[], groupSize = 1,): TableData {

    shuffle(players);

    let strongestPlayer = getStrongestPlayer(players);

    remove(players, strongestPlayer);

    console.log('Build a table, groupSize:', groupSize);
    console.log('Find opponent for', strongestPlayer.name)

    let potentialOpponent = null;
    let potentialOpponentsOrderedByPoints = getPlayersOrderedByPoints(players.filter(p => strongestPlayer.opponents.indexOf(p.id) < 0))

    let playersOfSameGroupCantMeet = selectUseGroup(store.getState()) && selectPlayersOfSameGroupCantMeet(store.getState());

    if (playersOfSameGroupCantMeet) {
        potentialOpponentsOrderedByPoints = potentialOpponentsOrderedByPoints.filter(p => p.group !== strongestPlayer.group);
    }


    for (let i = 0; i < potentialOpponentsOrderedByPoints.length; i++) {
        const group = potentialOpponentsOrderedByPoints.slice(i, i + groupSize);
        // Select a random player from the group
        const player = shuffle(group)[0];
        if (player) {
            potentialOpponent = player;
            remove(players, potentialOpponent);
            break;
        }
    }


    let table: TableData;
    table = {
        players: [{id: strongestPlayer.id, score: 0, win: 0, draw: 0, loss: 0}],
    };

    if (potentialOpponent !== null) {
        table.players.push({id: potentialOpponent.id, score: 0, win: 0, draw: 0, loss: 0});
        console.log("Built Table: ", strongestPlayer.name, potentialOpponent.name)

    } else {
        console.log("No opponent found.")
    }


    return table;

}

export function validateTables(tables: TableData[]) {
    for (var i = 0; i < tables.length; i++) {
        if (tables[i].players.length < 2) {
            console.log("Table " + i + " has less than 2 players");
            return false;
        }
    }
    return true;
}

export function isTableDone(table: TableData) {
    for (let player of table.players) {
        if (player.win !== 0 || player.draw !== 0 || player.loss !== 0) {
            return true;
        }
    }
    return false;
}

export function clearRound(round: Round, tableIndex: number) {
    round = deepCopy(round) as Round;
    for (let player of round.tables[tableIndex].players) {
        player.draw = 0;
        player.loss = 0;
        player.win = 0;
    }
    return round;
}

export function getRoundMax(players: PlayerStartData[]) {
    return Math.max(0, Math.ceil(Math.log2(players.length)))
}


export function createTables(players: PlayerData[], useExternalRanking: boolean, initialGroupSize = 1) {
    let tables: TableData[] = [];
    let groupSize = initialGroupSize;

    function buildTables(groupSize: number = 0) {
        console.log('buildTables, groupsize:', groupSize);
        tables = [];
        console.log('players', players)
        let playersToPair = [...players].filter(p => !p.dropped);
        while (playersToPair.length > 0) {
            if (useExternalRanking) {
                tables.push(buildATableWithExternalRank(playersToPair));
            } else {
                tables.push(buildATable(playersToPair, Math.max(1, groupSize)));
                groupSize--;
            }
        }
    }

    buildTables(groupSize);

    let test = 0;
    while (groupSize <= (players.length + 5)) {
        while ((test < (10 * groupSize)) && !validateTables(tables)) {
            buildTables(groupSize);
            test++;
        }

        if (validateTables(tables)) {
            break;
        }
        groupSize++;
        test = 0;
        console.log('augment group size', groupSize);
    }

    return tables;
}
