import {
    PlayerTableData,
    selectGame,
    selectPlayers,
    selectRoundCount,
    selectRounds,
    selectUseGoalAverage,
    selectUseGroup
} from "../../../appSlice";
import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {getPlayersOrderedByPoints, getPlayerTable, isTableDone} from "../../tournament/tournament-utils";
import {useTranslation} from "react-i18next";
import {RoundHistory} from "./RoundHistory";

function ImpressionRanking() {
    const {t} = useTranslation();
    const players = useAppSelector(selectPlayers);
    const rounds = useAppSelector(selectRounds);
    const useGoalAverage = useAppSelector(selectUseGoalAverage);
    const game = useAppSelector(selectGame);
    const roundCount = useAppSelector(selectRoundCount);
    const useGroup = useAppSelector(selectUseGroup);
    if (!roundCount) return <></>;

    return (
        <>
            <table className="impression-table" border={1}>
                <thead>
                <tr>
                    <th style={{maxWidth: "10px"}}>#</th>
                    <th>{t("player")}</th>
                    <th style={{maxWidth: "20px"}}>{t("table")}</th>
                    <th style={{maxWidth: "60px"}}>{t("points")}</th>
                    <th style={{maxWidth: "60px"}}>{t("W/D/L")}</th>
                    {useGoalAverage ? (
                        <>
                            {game !== "saga" ? <th>{t("score")}</th> : null}
                            <th>{game === "saga" ? t("GA") : t("GD")}</th>
                        </>
                    ) : null}
                    <th style={{maxWidth: "60px"}}>{t("resistance")}</th>
                </tr>
                </thead>
                <tbody>
                {getPlayersOrderedByPoints(players).map((player, index) => {
                    let table = getPlayerTable(rounds[roundCount - 1], player.id);
                    if (!table) {
                        return null;
                    }
                    let playerTableData = table.players.find(
                        (p) => p.id === player.id
                    ) as PlayerTableData;
                    let tableIndex = rounds[roundCount - 1].tables.indexOf(table) + 1;
                    return (
                        <tr
                            className={
                                (isTableDone(table) ? " done " : "") +
                                (playerTableData.win ? " win " : null) +
                                (playerTableData.loss ? " loss " : null) +
                                (playerTableData.draw ? " draw " : null)
                            }
                            key={player.id}
                        >
                            <td>{index + 1}</td>
                            <td>{player.name}{useGroup && <i> - {player.group}</i>}</td>
                            <td>{tableIndex}</td>
                            <td>{player.points}</td>
                            <td>
                                {player.win}/{player.draw}/{player.loss}
                            </td>
                            {useGoalAverage ? (
                                <>
                                    {game !== 'saga' ? <td>{player.score}</td> : null}
                                    <td>{player.gd}</td>
                                </>
                            ) : null}
                            <td>{player.resistance}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <RoundHistory removeLastRound={true}/>
        </>
    );
}

export default ImpressionRanking;
