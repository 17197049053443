import {useAppSelector} from "../../../app/hooks";
import {selectPlayerById, selectRounds, selectUseGoalAverage} from "../../../appSlice";
import {useTranslation} from "react-i18next";
import {currentState} from "../../../utils";
import React from "react";

export function RoundHistory(props: { removeLastRound: boolean }) {
    const {t} = useTranslation();
    const useGoalAverage = useAppSelector(selectUseGoalAverage);

    let rounds = [...useAppSelector(selectRounds)].sort((a, b) => b.id - a.id);

    if (rounds.length > 0 && props.removeLastRound) {
        rounds = rounds.slice(1, rounds.length);
    }

    if (!rounds) {
        return null;
    }

    <h2 style={{"marginTop": "60px"}}>{t('roundsHistory')}</h2>
    return (<>
        <h2 style={{"marginTop": "60px"}}>{t('roundsHistory')}</h2>

        {rounds.map((round) => (
            <div className="round" key={round.id}>
                <h3>{t('round')} #{round.id + 1}</h3>
                <table className="impression-table" border={1}>
                    <thead>
                    <tr>
                        <th>{t('table')}</th>
                        <th>{t('player')}</th>
                        {useGoalAverage ? <th>{t('score')}</th> : null}
                        <th>{t('W/D/L')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {round.tables.map((table, tableIndex) => (
                        <>
                            {table.players.map((p, playerIndex) => (
                                <tr key={p.id + " _ " + tableIndex}>
                                    {playerIndex === 0 ?
                                        <td className={"first"}>{tableIndex + 1}</td> : <td className="second"/>}
                                    <td>{selectPlayerById(p.id)(currentState()).name}</td>
                                    {useGoalAverage ? <td>{p.score}</td> : null}
                                    <td>{p.win}/{p.draw}/{p.loss}</td>
                                </tr>
                            ))}
                        </>
                    ))}
                    </tbody>
                </table>

            </div>
        ))
        }</>)
}