import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {
    PlayerStartData,
    selectDrawPoints,
    selectGame,
    selectLossPoints,
    selectMaxRounds,
    selectPlayers,
    selectPlayersOfSameGroupCantMeet,
    selectRoundDuration,
    selectRounds,
    selectTournamentName,
    selectTournamentState,
    selectUseExternalRanking,
    selectUseGoalAverage,
    selectUseGroup,
    selectWinPoints,
    setDrawPoints,
    setGame,
    setLossPoints,
    setMaxRounds,
    setPlayerOfSameGroupCantMeet,
    setPlayers,
    setRoundDuration,
    setTournamentName,
    setTournamentStatus,
    setUseExternalRanking,
    setUseGoalAverage,
    setUseGroup,
    setWinPoints,
    TournamentStatus
} from "../../appSlice";
import ToggleSwitch from "../ui/ToggleSwitch";
import {getUniqueId} from "../../utils";
import {getRoundMax} from "./tournament-utils";
import {CheckSVG} from '../edit/CheckSVG';
import {EditSVG} from "../edit/EditIcon";
import {TrashSVG} from "../edit/TrashSVG";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../LanguageSelector";
import {useT3Check} from "../../hooks";
import Version from "../ui/Version";
import Logo from "../ui/Logo";

const SetupTournament = () => {
    const dispatch = useAppDispatch();

    const tournamentStatus = useAppSelector(selectTournamentState);
    const winPoints = useAppSelector(selectWinPoints);
    const drawPoints = useAppSelector(selectDrawPoints);
    const lossPoints = useAppSelector(selectLossPoints);
    const roundDuration = useAppSelector(selectRoundDuration);
    const useExternalRanking = useAppSelector(selectUseExternalRanking);
    const players = useAppSelector(selectPlayers) as PlayerStartData[];
    const tournamentName = useAppSelector(selectTournamentName);
    const maxRounds = useAppSelector(selectMaxRounds);
    const rounds = useAppSelector(selectRounds);
    const useGoalAverage = useAppSelector(selectUseGoalAverage)
    const playerOfSameGroupCantMeet = useAppSelector(selectPlayersOfSameGroupCantMeet);
    const useGroup = useAppSelector(selectUseGroup);
    const game = useAppSelector(selectGame);
    const {t} = useTranslation();


    useT3Check();
    const handleGameChange = (event: any) => {
        const selectedGame = event.target.value;
        dispatch(setGame(selectedGame));
        if (selectedGame === 'saga') {
            dispatch(setUseGoalAverage(true));
        }
    };

    const [editingPlayerId, setEditingPlayerId]: [string, any] = useState("");
    const [newPlayerName, setNewPlayerName] = useState('');
    const [newPlayerGroup, setNewPlayerGroup] = useState('');
    const [newPlayerRank, setNewPlayerRank] = useState('');

    useEffect(() => {
        if (players.length === 0) {
            dispatch(setPlayers([
                                    {
                                        id: getUniqueId(),
                                        name: t("player") + " A",
                                        group: "",
                                        externalRank: 100
                                    },
                                    {
                                        id: getUniqueId(),
                                        name: t("player") + " B",
                                        group: "",
                                        externalRank: 300
                                    }
                                    ,
                                    {
                                        id: getUniqueId(),
                                        name: t("player") + " C",
                                        group: "",
                                        externalRank: 500
                                    },
                                    {
                                        id: getUniqueId(),
                                        name: t("player") + " D",
                                        group: "",
                                        externalRank: 1000
                                    }]));
        }
    }, []);

    const handleAddPlayer = () => {
        if (newPlayerName) {
            const newPlayer = {
                id: getUniqueId(),
                name: newPlayerName,
                group: newPlayerGroup,
                externalRank: parseInt(newPlayerRank) || 0
            } as PlayerStartData;
            dispatch(setPlayers([...players, newPlayer]));
            setNewPlayerName('');
            setNewPlayerGroup('');
            setNewPlayerRank('');
        }
    };

    const handleRemovePlayer = (index: number) => {
        const updatedPlayers = [...players];
        updatedPlayers.splice(index, 1);
        dispatch(setPlayers(updatedPlayers));
    };

    function handleEditingPlayer(player: PlayerStartData | null) {
        setEditingPlayerId(player?.id || "");
    }

    function doneEditingPlayer() {
        setEditingPlayerId("");
    }

    function handlePlayerNameChange(index: number, newName: string) {
        const updatedPlayers = [...players];
        updatedPlayers[index] = {...updatedPlayers[index]}
        updatedPlayers[index].name = newName;
        dispatch(setPlayers(updatedPlayers));
    }

    function handlePlayerGroupChange(index: number, newGroup: string) {
        const updatedPlayers = [...players];
        updatedPlayers[index] = {...updatedPlayers[index]}
        updatedPlayers[index].group = newGroup;
        dispatch(setPlayers(updatedPlayers));
    }

    function handlePlayerRankChange(index: number, newRank: string) {
        const updatedPlayers = [...players];
        updatedPlayers[index] = {...updatedPlayers[index]}
        updatedPlayers[index].externalRank = parseInt(newRank) || 0;
        setPlayers(updatedPlayers);
    }

    function handleSubmitTournament() {
        if (!maxRounds) {
            dispatch(setMaxRounds(getRoundMax(players)))
        }
        if (tournamentStatus === TournamentStatus.PREPARING) {
            dispatch(setTournamentStatus(TournamentStatus.STARTED));

        } else if (tournamentStatus === TournamentStatus.EDITING) {
            dispatch(setTournamentStatus(TournamentStatus.STARTED));
        }
    }

    return (
        <div className="container">
            <header>
                <Logo/>
                <Version/>
                <LanguageSelector/>
                <h2>{tournamentStatus === TournamentStatus.PREPARING ? t('newTournament') : t('editTournament')}</h2>
                {tournamentStatus === TournamentStatus.EDITING ? <div className={"tournament-actions"}>
                    <button
                        type="submit"
                        className="link link-small"
                        onClick={() => {
                            dispatch(setTournamentStatus(TournamentStatus.STARTED))
                        }}
                    >
                        {t('returnToTournament')}
                    </button>
                </div> : null}

            </header>


            <div className="box">
                <h3>{t('players')} ({players.length})</h3>
                <form>

                    <div className="player-inputs">
                        <input
                            type="text"
                            placeholder={t('playerName') || ''}
                            value={newPlayerName}
                            onChange={(e) => setNewPlayerName(e.target.value)}
                        />

                        {useGroup ?
                            <input
                                type="text"
                                placeholder={t('groupName') || ''}
                                value={newPlayerGroup}
                                onChange={(e) => setNewPlayerGroup(e.target.value)}
                            /> : null}

                        {useExternalRanking ?
                            <input
                                type="number"
                                placeholder={t('rank') || ''}
                                value={newPlayerRank}
                                onChange={(e) => setNewPlayerRank(e.target.value)}
                            />
                            : null}
                        <button type="submit" disabled={!newPlayerName} className="player-inputs-button secondary"
                                onClick={handleAddPlayer}>
                            {t('add')}
                        </button>
                    </div>
                </form>

                <table>
                    <thead>
                    <tr>
                        <th style={{"display": "none"}}></th>
                        <th><span className="txt">{t('name')}</span></th>
                        {useGroup ? <th><span className="txt">{t('group')}</span></th> : null}
                        {useExternalRanking ? <th><span className="txt">{t('rank')}</span></th> : null}
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {players
                        .map((player: PlayerStartData, index) => (
                            <tr key={index}>
                                <td style={{"display": "none"}}></td>
                                <td>
                                    {editingPlayerId === player.id ? (
                                        <input
                                            type="text"
                                            value={(player as PlayerStartData).name}
                                            onChange={(e) => handlePlayerNameChange(index, e.target.value)}
                                        />
                                    ) : (
                                        player.name
                                    )}
                                </td>
                                {useGroup ? <td>
                                    {editingPlayerId === player.id ? (
                                        <input
                                            type="text"
                                            value={(player as PlayerStartData).group}
                                            onChange={(e) => handlePlayerGroupChange(index, e.target.value)}
                                        />
                                    ) : (
                                        player.group
                                    )}
                                </td> : null}
                                {useExternalRanking ? (
                                    <td>
                                        {editingPlayerId === player.id ? (
                                            <input
                                                type="number"
                                                value={(player as PlayerStartData).externalRank || ''}
                                                onChange={(e) => handlePlayerRankChange(index, e.target.value)}
                                            />
                                        ) : (
                                            player.externalRank
                                        )}
                                    </td>
                                ) : null}

                                <td align="right" width={50}>

                                    <div className="table-actions">
                                        {editingPlayerId === player.id ?
                                            <button
                                                type="submit"
                                                className="icon-button edit"
                                                onClick={() => doneEditingPlayer()}
                                            >
                                                <CheckSVG/>
                                            </button> : null}

                                        {editingPlayerId !== player.id ?
                                            <button
                                                className="icon-button edit"
                                                onClick={() => handleEditingPlayer(player)}
                                            >
                                                <EditSVG/>
                                            </button> : null}


                                        <button
                                            disabled={rounds.length > 0}
                                            className="icon-button delete"
                                            onClick={() => handleRemovePlayer(index)}
                                        >
                                            <TrashSVG/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="box">
                <h3>{t('settings')}</h3>

                <div className="columns">
                    <div className="column">
                        <div>
                            <label>
                                {t('tournamentName')}
                            </label>
                            <input
                                type="text"
                                placeholder={t('placeholderTournamentName') || ''}
                                value={tournamentName}
                                onChange={(e) => dispatch(setTournamentName(e.target.value))}
                            />
                        </div>
                        <hr/>

                        <div>
                            <label>
                                {t('pointsPerWin')}:
                                <input
                                    type="number"
                                    value={winPoints}
                                    onChange={(e) => dispatch(setWinPoints(parseInt(e.target.value)))}
                                />
                            </label>
                        </div>

                        <div>
                            <label>
                                {t('pointsPerDraw')}:
                                <input
                                    type="number"
                                    value={drawPoints}
                                    onChange={(e) => dispatch(setDrawPoints(parseInt(e.target.value)))}
                                />
                            </label>
                        </div>

                        <div>
                            <label>
                                {t('pointsPerLoss')}:
                                <input
                                    type="number"
                                    value={lossPoints}
                                    onChange={(e) => dispatch(setLossPoints(parseInt(e.target.value)))}
                                />
                            </label>
                        </div>

                        <hr/>
                        <div>
                            <label>
                                {t('numRounds')}:
                                <input
                                    type="number"
                                    value={maxRounds}
                                    onChange={(e) => dispatch(setMaxRounds(parseInt(e.target.value)))}
                                />
                            </label>
                        </div>

                        <div>
                            <label>
                                {t('roundDuration')}:
                                <input
                                    type="number"
                                    value={roundDuration}
                                    onChange={(e) => dispatch(setRoundDuration(parseInt(e.target.value)))}
                                />
                            </label>
                        </div>


                    </div>

                    <div className="column">
                        <div>
                            <label>
                                {t('addGroupNamePerPlayer')}:
                                <br/>
                                <ToggleSwitch
                                    checked={useGroup}
                                    onChange={(e) => {
                                        dispatch(setUseGroup(!useGroup))
                                    }}
                                />
                            </label>
                        </div>

                        {useGroup && <div>
                            <label>
                                {t('playerOfSameGroupCantMeet')}:
                                <br/>
                                <ToggleSwitch
                                    checked={playerOfSameGroupCantMeet}
                                    onChange={(e) => {
                                        dispatch(setPlayerOfSameGroupCantMeet(!playerOfSameGroupCantMeet))
                                    }}
                                />
                            </label>
                        </div>}
                        <div>
                            <label>
                                {t('useExternalRankToSeparate')}:
                                <br/>
                                <ToggleSwitch
                                    checked={useExternalRanking}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        dispatch(setUseExternalRanking(!useExternalRanking))
                                    }}
                                />
                            </label>
                        </div>

                        <div>
                            <label>
                                {t('addScoreAndGD')}:
                                <br/>
                                <ToggleSwitch
                                    disabled={game === 'saga'}
                                    checked={useGoalAverage}
                                    onChange={(e) => {
                                        dispatch(setUseGoalAverage(!useGoalAverage))
                                    }}
                                />
                            </label>
                        </div>


                        <div>
                            <label>
                                {t('useSpecificGameRules')}:
                                <br/>
                                <select value={game} onChange={handleGameChange}>
                                    <option value="">{t('noRules')}</option>
                                    <option value="saga">{t('saga')}</option>
                                </select>
                            </label>
                        </div>

                        <div className={"info"}><br/>
                            <p><i>{t('gameRulesInfo')}</i></p>
                        </div>

                    </div>
                </div>
            </div>

            {tournamentStatus === TournamentStatus.PREPARING ?
                <button className="start-button" disabled={players.length < 2} onClick={handleSubmitTournament}>
                    {t('launchNewTournament')}
                </button> : null}
        </div>
    );
};

export default SetupTournament;
