import React from 'react';
import logo from "../../assets/rondesuisselogo.png";


function Logo() {
    return (
        <a href="https://larondesuisse.com"><img className="logo" alt="logo" src={logo}/></a>
    );
};

export default Logo;