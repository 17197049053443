import {selectTournamentState, TournamentStatus} from "../../appSlice";
import SetupTournament from "../tournament/SetupTournament";
import RunningTournament from "../tournament/RunningTournament";
import React from "react";
import {useAppSelector} from "../../app/hooks";

import {useTranslation} from "react-i18next";
import Footer from "./Footer";

function Home() {

    const tournamentStatus = useAppSelector(selectTournamentState)
    const [t] = useTranslation();

    return (
        <>
            {tournamentStatus === TournamentStatus.PREPARING ||
            tournamentStatus === TournamentStatus.EDITING ?
                <SetupTournament/>
                :
                <RunningTournament/>
            }
            <Footer/>
        </>
    );

}

export default Home;
