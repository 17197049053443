import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {getPlayersOrderedByName, getPlayerTable, isTableDone} from "../../tournament/tournament-utils";
import {currentState} from "../../../utils";
import {useTranslation} from "react-i18next";
import {
    PlayerTableData,
    selectPlayerById,
    selectPlayers,
    selectRoundCount,
    selectRounds,
    selectUseGroup
} from "../../../appSlice";
import {RoundHistory} from "./RoundHistory";

function ImpressionPairing() {
    const {t} = useTranslation();
    const players = useAppSelector(selectPlayers);
    const rounds = useAppSelector(selectRounds);
    const roundCount = useAppSelector(selectRoundCount);
    const useGroup = useAppSelector(selectUseGroup);

    if (!roundCount) return <></>;

    return (
        <>
            <table className="impression-table" border={1}>
                <thead>
                <tr>
                    <th style={{maxWidth: "20px"}}>{t("table")}</th>
                    <th>{t("player")}</th>
                    <th>{t("opponent")}</th>
                    <th style={{maxWidth: "60px"}}>{t("points")}</th>
                </tr>
                </thead>
                <tbody>
                {getPlayersOrderedByName(players).map((player) => {
                    let table = getPlayerTable(rounds[roundCount - 1], player.id);

                    if (!table) {
                        return null;
                    }

                    let playerTableData = table.players.find((p) => p.id === player.id) as PlayerTableData;
                    let opponentId = table.players.find((p) => p.id !== player.id)?.id;
                    let opponent = opponentId ? selectPlayerById(opponentId)(currentState()) : null;
                    let tableIndex = rounds[roundCount - 1].tables.indexOf(table) + 1;

                    return (
                        <tr
                            className={
                                (isTableDone(table) ? " done " : "") +
                                (playerTableData.win ? " win " : null) +
                                (playerTableData.loss ? " loss " : null) +
                                (playerTableData.draw ? " draw " : null)
                            }
                            key={player.id}
                        >
                            <td>{tableIndex}</td>
                            <td>{player.name}{useGroup && <i> - {player.group}</i>}</td>
                            <td>{opponent?.name ? opponent.name : "Bye"}</td>
                            <td>
                                {player.points}
                                {opponent?.points !== undefined ? " - " + opponent?.points : ""}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <RoundHistory removeLastRound={true}/>
        </>
    );
}

export default ImpressionPairing;
