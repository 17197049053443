import {Action, configureStore, Middleware, ThunkAction} from '@reduxjs/toolkit';
import {appSlice} from '../appSlice';

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined; // Return undefined to use the initial state from the reducer
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error('Error loading state from local storage:', err);
        return undefined;
    }
};

const saveStateToLocalStorageMiddleware: Middleware = (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.error('Error saving state to local storage:', err);
    }
    return result;
};

const initialState: any = loadStateFromLocalStorage();


export const store = configureStore({
    reducer: {
        "app": appSlice.reducer,
    },
    preloadedState: initialState ? initialState : undefined,
    middleware: [saveStateToLocalStorageMiddleware]

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
