import React from 'react';
import './style.css';
import {useAppSelector} from "./app/hooks";
import {selectModals} from "./appSlice";
import Modal from "./features/modal/Modal";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Impression from "./features/pages/Impression";
import Home from "./features/pages/Home";
import Download from "./features/pages/Download";
import Changelog from "./features/pages/Changelog";

function App() {

    const modals = useAppSelector(selectModals);

    const validDomain = 'larondesuisse.com';
    const currentDomain = window.location.hostname;

    if (currentDomain !== validDomain && !currentDomain.includes("localhost")) {
        return <></>
    }

    return (
        <div className="app">

            <Router>
                <Routes>
                    <Route path="/changelog" element={<Changelog/>}/>
                    <Route path="/impression" element={<Impression/>}/>
                    <Route path="*" element={<Home/>}/>

                </Routes>
            </Router>

            {modals.length > 0 && (
                <div className="modal-overlay">
                    {modals.map((modal, index) => (
                        <Modal
                            key={index}
                            modalData={modal}
                        />
                    ))}
                </div>
            )}

            <div style={{"display": "none"}}>
                <div id="pdf" style={{"width": "1400px", "padding": "30px"}}>
                    <Download/>
                </div>
            </div>
        </div>
    );
}

export default App;
